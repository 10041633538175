#App {

}

.web3modal-modal-lightbox {
  z-index: 10 !important;
}

button:focus {
  outline: none;
}
