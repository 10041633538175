.speech-bubble {
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  padding: 5px;
}

.speech-bubble-left:after {
  content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-right-color: rgba(0, 0, 0, 0.8);
	border-left: 0;
	border-bottom: 0;
	margin-top: -26.5px;
	margin-left: -53px;
}

.speech-bubble-right:after {
  content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-left-color: rgba(0, 0, 0, 0.8);
	border-right: 0;
	border-bottom: 0;
	margin-top: -26.5px;
	margin-right: -53px;
}

.speech-bubble-bottom:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 53px solid transparent;
	border-top-color: rgba(0, 0, 0, 0.8);
	border-bottom: 0;
	border-right: 0;
	margin-left: -26.5px;
	margin-bottom: -53px;
}
